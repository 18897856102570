import JohnK from "./assets/boardmembers/John-Kocjan.jpg";

import SarahJ from "./assets/boardmembers/Sarah-Jamieson.jpg";

import SteveF from "./assets/boardmembers/Steve-Fulford.jpg";

import JimA from "./assets/boardmembers/Jim-Anderson.jpg";

import DavidB from "./assets/boardmembers/David-Bremner.jpg";

import PeterF from "./assets/boardmembers/Peter-Ferrante.jpg";

import IanH from "./assets/boardmembers/Ian-Henderson.jpg";

import NorineJ from "./assets/boardmembers/Norine-Jones.jpg";

import JeffL from "./assets/boardmembers/Jeff-Lainsbury.jpg";

import KellyS from "./assets/boardmembers/Kelly-Newell.jpg";

import GlennS from "./assets/boardmembers/Glenn-Smith.jpg";

import MartinL from "./assets/boardmembers/Martin-Ling.jpg";

import CatherineM from "./assets/boardmembers/Catherine-Mills.jpg";

import JoelW from "./assets/boardmembers/Joel-Weerdenburg.jpg";

import BruceDecker from "./assets/boardmembers/BruceDecker.jpg";

import MHarrett from "./assets/boardmembers/MHarrett.jpg";

export default [
  {
    title: "President",
    name: "Peter Ferrante",
    company: "Ferro Canada Inc.",
    address: "955 Alliance Rd., Unit 2, Pickering, ON L1W 3M8",
    phone: "905-841-8108",
    fax: "905-841-7174",
    email: "peter@ferrocanada.com",
    image: PeterF,
  },
  {
    title: "Vice President",
    name: "Sarah Jamieson",
    company: "EMSL Canada, Inc.",
    address: "2756 Slough Street, Mississauga, ON L4T 1G3",
    phone: "289-997-4602",
    fax: "289-997-4607",
    email: "sjamieson@emsl.com",
    image: SarahJ,
  },

  {
    title: "Past President",
    name: "John Kocjan",
    company: "ECOH Management Inc.",
    address: "75 Courtneypark Drive West, Unit 1, Mississauga, ON, L5W 0E3",
    phone: "905-795-2800 ",
    fax: "905-795-2870",
    email: " jkocjan@ecoh.ca",
    image: JohnK,
  },
  {
    title: "Treasurer",
    name: "David Bremner",
    company: "Environmental Response Team (ERT)",
    address: "2850 Lakeshore Blvd W, Etobicoke, Ontario M8V 4A1",
    phone: "416 -255-6745",
    fax: "416-255-6485",
    email: "davidbremner@erthazmat.com",
    image: DavidB,
  },
  {
    title: "Directors At Large",
    name: "Jim Anderson",
    // company: "GB Environmental Services (Niagara) Ltd",
    // address: "12 - 111 Fourth Ave., Suite 352, St Catharines, ON L2S 3P5",
    // phone: "905-984-3455",
    // email: "janderson@gbenvironmental.net",
    image: JimA,
  },

  {
    name: "Bruce Decker",
    company: "BGIS",
    address: "4175 14th Ave, Markham, Ontario L3R 0J2",
    phone: "647-206-5388",
    email: "bruce.decker@bgis.com",
    image: BruceDecker,
  },
  {
    name: "Mike Harrett",
    company: "Pinchin Ltd.",
    address: "1 Hines Road, Suite 200, Kanata, Ontario K2K 3C7",
    phone: "613-484-3370",
    email: "mharrett@Pinchin.com",
    image: MHarrett,
  },
  {
    name: "Ian Henderson",
    company: "H&S Specialties Inc.",
    address: "26 Whitefoot Crescent, Ajax, ON L1Z 2E1",
    phone: "416-556-5893",
    email: "ihenderson@hsspecialties.com",
    image: IanH,
  },
  {
    name: "Norine Jones",
    company: "Power Vac Services and Power Environmental Management",
    address: "321 Anchor Rd. #2, Hamilton, ON L8W 3R1",
    phone: "905-318-0622",
    fax: "",
    email: "njones@powervachamilton.ca",
    image: NorineJ,
  },

  {
    name: "Martin Ling",
    company: "Stantec",
    address: "300W-675 Cochrane Drive, Markham, ON L3R 0B8",
    phone: "905-415-6386",
    fax: "905-474-9889",
    email: "martin.ling@stantec.com",
    image: MartinL,
  },
  {
    name: "Catherine Mills",
    company: "ECOH Management Inc.",
    address: "75 Courtneypark Drive West, Unit 1, Mississauga, ON L5W 0E3",
    phone: "416-640-2444",
    email: "cmills@ecoh.ca",
    image: CatherineM,
  },
  {
    name: "Glenn Smith",
    company: "Safetech Environmental Ltd.",
    address: "3045 Southcreek Road, Unit #14, Mississauga, ON L4X 2X7",
    phone: "905-624-2722",
    fax: "905-624-4306",
    email: "gsmith@safetechenv.com",
    image: GlennS,
  },
  {
    name: "Joel Weerdenburg",
    company: "QM Environmental",
    address: "200-5035 South Service Road, Burlington, ON L7L 6M9",
    phone: "289-237-7734",
    email: "Joel.Weerdenburg@QMenv.com",
    image: JoelW,
  },
];

import React from "react";
import "../PhotoGalleryPage/PhotoGalleryPage.scss";

import Hero from "../../components/Hero/Hero";
import Gallery from "../../components/Gallery/Gallery";

import data2023 from "../../2023gallery.json";
import data2022 from "../../2022gallery.json";
import data2021 from "../../2021gallery.json";
import data2020 from "../../2020gallery.json";
import data2019 from "../../2019gallery.json";
import data2018 from "../../2018gallery.json";
import data2017 from "../../2017gallery.json";
import data2016 from "../../2016gallery.json";
import data2015 from "../../2015gallery.json";
import data2014 from "../../2014gallery.json";
import data2012 from "../../2012gallery.json";
import data2010 from "../../2010gallery.json";
import data2009 from "../../2009gallery.json";
import data2008 from "../../2008gallery.json";
import data2006 from "../../2006gallery.json";
import data2005 from "../../2005gallery.json";

function PhotoGalleryPage() {
  return (
    <>
      <Hero title={"Photo Galleries"} />
      <div className="photo-gallery-page">
        <Gallery year={"2023"} data={data2023} />
        <Gallery year={"2022"} data={data2022} />
        <Gallery year={"2021"} data={data2021} />
        <Gallery year={"2020"} data={data2020} />
        <Gallery year={"2019"} data={data2019} />
        <Gallery year={"2018"} data={data2018} />
        <Gallery year={"2017"} data={data2017} />
        <Gallery year={"2016"} data={data2016} />
        <Gallery year={"2015"} data={data2015} />
        <Gallery year={"2014"} data={data2014} />
        <Gallery year={"2012"} data={data2012} />
        <Gallery year={"2010"} data={data2010} />
        <Gallery year={"2009"} data={data2009} />
        <Gallery year={"2008"} data={data2008} />
        <Gallery year={"2006"} data={data2006} />
        <Gallery year={"2005"} data={data2005} />
      </div>
    </>
  );
}

export default PhotoGalleryPage;

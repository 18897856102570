import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../../../components/Hero/Hero";
import MembersComponent from "../../../components/MembersComponent/MembersComponent";
import PreQualifiedContractorsList from "../../../PreQualifiedContractors";
import ConsultantsList from "../../../Consultants";
import GeneralMembersList from "../../../GeneralMembers";
import AssociatesList from "../../../Associates";

function MembersList() {
  const [data, setData] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const params = useParams();

  const paramCheck = () => {
    if (params.page === "pre-qualified-contractors") {
      setData(PreQualifiedContractorsList);
      setSubtitle("Pre-Qualified Contractors");
    }
    if (params.page === "consultants") {
      setData(ConsultantsList);
      setSubtitle("Pre-Qualified Consultants");
    }
    if (params.page === "general-members") {
      setData(GeneralMembersList);
      setSubtitle("General Members");
    }
    if (params.page === "associates") {
      setData(AssociatesList);
      setSubtitle("Associates");
    }
  };

  useEffect(() => {
    paramCheck();
  }, []);

  return (
    <div>
      <Hero title={"Members"} subtitle={subtitle} />
      {data && <MembersComponent data={data} />}
    </div>
  );
}

export default MembersList;

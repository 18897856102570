import "./News.scss";
import Calender from "../../../assets/icons/calender.png";
import Quote from "../../../assets/icons/quote.png";

function News() {
  return (
    <div className="news">
      <div className="news__card news__card--left">
        <img className="news__image" src={Calender} alt="Calender Icon" />
        <h3 className="news__title">Upcoming Events</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">EACC Golf Day 2024</p>
 
          <p className="news__text news__text--padding">
            September 18th | 11:30AM - 8:00PM
            <br />
            Tickets includes: GOLF, CART, LUNCH AND DINNER <br />
            Each golfer will receive a pair of Adidas Golf Shoes.
          </p>
          <p><span>Location:</span>{" "}Wooden Sticks Golf Club, Uxbridge</p>
          <a
            className="news__event-link"
            href={
              "https://lp.constantcontactpages.com/ev/reg/n54852v/lp/fd239fcd-c84e-434a-b1a3-e93fe4f4b235?source_id=46264795-4d47-43e3-84bc-1a0f4971041c&source_type=em&c=p2Ben3-tFKIIFI6eWjsV5hrE17ZN9P6L0j8IMwYdcMl5-dVNP4BKOg=="
            }
          >
            Learn More
          </a>
        </div>
      </div>
      <div className="news__card">
        <img className="news__image" src={Quote} alt="Speech Bubble Icon" />
        <h3 className="news__title">News & Announcements</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">
            News: EACC Members Contribute to CSA Asbestos Report
          </p>
          <p className="news__text news__text--padding">
            EACC members provided input to researchers hired by the CSA Group to
            explore potential gaps and best practices in asbestos management in
            Canada and to determine if there is a need for the development of a
            national standard.
          </p>
          <p className="news__text">
            The CSA Asbestos report is now available on their{" "}
            <a
              className="news__event-link"
              href="https://www.csagroup.org/article/research/asbestos-management-in-canada-assessing-the-need-for-a-national-standard/"
            >
              website
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
export default News;

import Hero from "../../components/Hero/Hero";
import "./EventsPage.scss";

import Award from "../../assets/EACO-award.jpeg";
import { Link } from "react-router-dom";

function EventsPage() {
  return (
    <>
      <Hero title="News & Events" />
      <div className="events">
        <div className="events__card events__card--padding">
          {/* <h3 className="events__heading">
            Stay tuned for upcoming event details.
          </h3> */}
          <h3 className="events__heading">EACC Golf Day 2024</h3>
          <p className="events__info">
            Date: September 18th, 2024
            <br /> <br />
            Location: WOODEN STICKS GOLF CLUB, UXBRIDGE
            <br /> <br />
            Tickets includes: GOLF, CART, LUNCH AND DINNER <br />
            Each golfer will receive a pair of Adidas Golf Shoes.
          </p>

          <a
            href={
              "https://lp.constantcontactpages.com/ev/reg/n54852v/lp/fd239fcd-c84e-434a-b1a3-e93fe4f4b235?source_id=46264795-4d47-43e3-84bc-1a0f4971041c&source_type=em&c=p2Ben3-tFKIIFI6eWjsV5hrE17ZN9P6L0j8IMwYdcMl5-dVNP4BKOg=="
            }
            className="events__button"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
          <div className="events__divider"></div>

          {/* <a
            href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejwc5uxn20ce7f77&llr=ipefsquab"
            className="events__button"
            target="_blank"
          >
           
            Registration
          </a> */}
        </div>
        <div className="events__card">
          <h3 className="events__heading events__heading--padding">News</h3>
          <h5 className="events__subheading">
            Press Release <br />
            June 10, 2021
          </h5>

          <h5 className="events__subheading">
            EACO is now EACC, the Environmental Abatement Council of Canada.
          </h5>

          <p className="events__text events__text--padding">
            EACO President, John Kocjan, launches the new Environmental
            Abatement Council of Canada at its Annual General Meeting.
          </p>

          <Link className="events__link" to="/press-release">
            Read the Press Release here.
          </Link>

          <div className="events__divider"></div>
          <p className="events__text">
            <img className="events__image" src={Award} alt="EACC Award" />
            EACC’s Past President, Steve Fulford, holds IHSA’s Gil Samson Award
            presented to the Demolition Labour-Management Health and Safety
            Committee. Steve sits on the committee as EACC’s representative. The
            committee was honoured for its work including identifying the need
            to address occupational disease in construction and working with
            EACC to develop a guideline to standardize the content and format of
            a designated substance survey. Steve, as Chair of the DSS Committee,
            said “EACC is commended for its leadership in contributing to such
            an important industry initiative” as well he thanked the committee
            participants who volunteered their time to develop the guide which
            will be published later this year.
          </p>
        </div>
      </div>
    </>
  );
}
export default EventsPage;

export default [
  {
    id: 1,
    contacts: [
      {
        company: "ALL-TECH Environmental Services Limited",
        name: "Terry Smith",
        phone: "902-835-3727",
        fax: "902-835-5266",
        email: "tsmith@toalltech.com",
        address: "162 Trider Crescent",
        city: "Dartmouth",
        province: "Nova Scotia",
        postal: "B3B 1R6",
        website: "www.toalltech.com",
        latitude: 44.70755656632544,
        longitude: -63.58981264461443,
      },
    ],
    logo: "/images/logos/consultants/All-Tech.png",
    profile: [
      "ALL-TECH is an Atlantic Canada owned and operated company that specializes in environmental services, specifically ambient/indoor air quality, fume hood/biological containment cabinet commissioning and certifications, hazardous building materials testing and assessments, infection control, environment (contaminated sites), legionella management plans and sampling, mould and moisture assessments, project management, radon gas testing, safety consulting services, occupational hygiene services, and safety/awareness training.",
      "Our head office is located at 162 Trider Crescent, Dartmouth, Nova Scotia, with branch offices located in Sydney, Nova Scotia; Belledune, Moncton and Saint John, New Brunswick; Charlottetown, Prince Edward Island; and Corner Brook, Gander, and Mount Pearl, Newfoundland and Labrador.",
      "Since 1993, ALL-TECH has provided Federal, Provincial and Municipal Government clients, as well as private clients with cost effective and timely solutions on a broad range of Environmental services. Our success is built upon our commitment to provide direct client communication and our ability to focus quickly on key issues to achieve practical scientific solutions. This commitment has led to a well-established reputation of servicing clients throughout Atlantic Canada. Our mission is to assist our clients in reaching their objectives towards a safe and healthy workspace and/or environment through assessment, regular monitoring, consulting, and management of hazardous materials. Our cooperation in site management begins with an emphasis on education. We believe that knowledge and understanding are paramount in the progression towards health, safety, and environmental compliance.",
    ],
  },
  {
    id: 2,
    contacts: [
      {
        company: "Apex EHS Services Inc.",
        name: "Jeff Widmer",
        phone: "250-868-0667",
        fax: "",
        email: "jwidmer@apexehs.ca",
        address: "1519 Keehn Road",
        city: "Kelowna",
        province: "British Columbia",
        postal: "V1X 5T5",
        website: "www.apexehs.ca",
        latitude: 49.89036302213087,
        longitude: -119.41717339581562,
      },
    ],
    logo: "/images/logos/consultants/apex-ehs.png",
    profile: [
      "Apex EHS Services Inc. (Apex) was established by a group of occupational health and safety consultants with the goal to operate a full-service hazardous material consulting firm that could meet its client’s tight deadlines with accurate and professional results. We have offices in Kelowna, Castlegar and Kamloops, BC.",
      "pex has extensive experience in conducting hazardous materials surveys, supervising hazardous material abatement projects, asbestos air monitoring, mould assessments, and liaising with regulatory bodies on behalf of clients to ensure guidelines and regulations are being followed. Additionally, Apex has an in-house laboratory offering asbestos bulk, asbestos air, lead paint and mould sample analytical services.",
    ],
  },
  {
    id: 3,
    contacts: [
      {
        company: "Arcadis Canada Inc.",
        name: "Kelly Smith",
        phone: "905-764-9380",
        fax: "",
        email: "Kelly.M.Smith@arcadis.com.",
        address: "121 Granton Drive, Suite 12",
        city: "Richmond Hill",
        province: "Ontario",
        postal: "L4B 3N4",
        website: "www.arcadis.com",
        latitude: 43.860464495579876,
        longitude: -79.38788092969872,
      },
    ],
    logo: "/images/logos/consultants/Arcadis.jpg",
    profile: [
      "Established in 1990, DCS, an Arcadis Company, is a highly-qualified and respected, industry-leading environmental engineering consulting firm providing a complete range of consulting services related to Designated Substances, Hazardous Materials, Asbestos and Mould Management and Assessment, IAQ, Industrial Hygiene and Training. Arcadis has offices in Richmond Hill, Ottawa and the Niagara Peninsula.",
    ],
  },
  {
    id: 4,
    contacts: [
      {
        company: "BGIS",
        name: "Steve Fulford",
        phone: "416-737-9375",
        fax: "",
        email: "Steve.Fulford@bgis.com",
        address: "4175 14th Ave",
        city: "Markham",
        province: "Ontario",
        postal: "L3R 5R5",
        website: "www.bgis.com",
        latitude: 43.83914402013964,
        longitude: -79.3163978297094,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 5,
    contacts: [
      {
        company: "BluMetric Environmental Inc. (formerly WESA)",
        name: "Lydia Renton",
        phone: "613-839-3053",
        fax: "613-839-5376",
        email: " lrenton@blumetric.ca",
        address: "1682 Woodward Drive",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2C 3R8",
        website: "www.blumetric.ca",
        latitude: 45.37313503686645,
        longitude: -75.74877552880574,
      },
      {
        company: "BluMetric Environmental Inc. (formerly WESA)",
        name: "Lydia Renton",
        phone: "416-383-0957",
        fax: "",
        email: "toronto@blumetric.ca",
        address: "825 Milner Avenue",
        city: "Scarborough",
        province: "Ontario",
        postal: "M1B 3C3",
        website: "www.blumetric.ca",
        latitude: 43.799076340938264,
        longitude: -79.19942311438449,
      },
      {
        company: "BluMetric Environmental Inc. (formerly WESA)",
        name: "Lydia Renton",
        phone: "514-844-7199",
        fax: "",
        email: "montreal@blumetric.ca",
        address: "276, rue Saint-Jacques, bureau 818",
        city: "Montréal",
        province: "Ontario",
        postal: "H2Y 1N3",
        website: "www.blumetric.ca",
        latitude: 45.502785909106436,
        longitude: -73.55868622886034,
      },
      {
        company: "BluMetric Environmental Inc. (formerly WESA)",
        name: "Lydia Renton",
        phone: "705-525-6075",
        fax: "",
        email: "sudbury@blumetric.ca",
        address: "410 Falconbridge Road, Unit 6",
        city: "Sudbury",
        province: "Ontario",
        postal: "P3A 4S4",
        website: "www.blumetric.ca",
        latitude: 46.508480902405985,
        longitude: -80.93420432833523,
      },
    ],
    logo: "/images/logos/consultants/BM.png",
    profile: [
      "BluMetric is a Canadian environmental consulting and water cleantech company that solves environmental problems. The BluMetric occupational hygiene team is committed to protecting the health and safety of workers by anticipating, recognizing, assessing, and controlling chemical, physical, and biological occupational hazards. Experts in federal and provincial regulations, we advise our clients on everything they need to know (and do) to keep their workforce healthy, safe, and compliant.",
    ],
  },
  {
    id: 6,
    contacts: [
      {
        company: "Cambium Inc.",
        name: "Jeremy Taylor",
        phone: "705-742-7900",
        fax: "",
        email: " Jeremy.Taylor@cambium-inc.com",
        address: "194 Sophia Street",
        city: "Peterborough",
        province: "Ontario",
        postal: "K9H 1E5",
        website: "www.cambium-inc.com",
        latitude: 44.30639300447504,
        longitude: -78.3113983851361,
      },
    ],
    logo: "/images/logos/consultants/cambium.png",
    profile: [
      "Cambium is a multi-service consulting and engineering company, focused on meeting your project needs with innovative and practical solutions. We are an employee-owned company offering integrated high-quality expertise for new and existing developments in the areas of: Environmental, Building Sciences, Geotechnical Engineering, and Construction Quality Verification (incl. CCIL Materials Testing Lab).",
      "With offices strategically placed in the GTA, central, and eastern Ontario, including Peterborough, Kingston, Barrie, Durham Region, and our newest office in Calgary Alberta, we are able to competitively service all of your projects in this area.",
    ],
  },
  {
    id: 7,
    contacts: [
      {
        company: "CM3 Environmental Inc.",
        name: "Dave Morroz",
        phone: "613-838-2323",
        fax: "",
        email: "dave@cm3environmental.com",
        address: "5710 Akins Road",
        city: "Stittsville",
        province: "Ontario",
        postal: "K2S 1B8",
        website: "www.cm3environmental.com",
        latitude: 45.23400376558072,
        longitude: -75.85183341365416,
      },
    ],
    logo: "/images/logos/consultants/cm3.png",
    profile: [],
  },
  {
    id: 8,
    contacts: [
      {
        company: "ECOH Management Inc.",
        name: "John Kocjan",
        phone: "905-795-2800",
        fax: "905-795-2870",
        email: "jkocjan@ecoh.ca",
        address: "75 Courtneypark Drive West, Unit 1",
        city: "Mississauga",
        province: "Ontario",
        postal: "L5W 0E3",
        website: "www.ecoh.ca",
        latitude: 43.63615462535928,
        longitude: -79.69699434515637,
      },
      {
        company: "ECOH Management Inc.",
        name: "Christopher Nielsen",
        phone: "613-699-2295 ext. 2220",
        fax: "",
        email: "cnielsen@ecoh.ca",
        address: "666 Kirkwood Avenue, Unit 100",
        city: "Ottawa",
        province: "Ontario",
        postal: "K1Z 5X9",
        website: "www.ecoh.ca",
        latitude: 45.38547497611864,
        longitude: -75.74118435767252,
      },
    ],
    logo: "/images/logos/consultants/ecoh.jpg",
    services:
      "Asbestos, Indoor Air Quality, Lead, Mould, Vermiculite, any Hazardous Materials",
    profile: [
      "Protecting People, Property and Planet since 2000, ECOH is a respected industry leader of Environmental, Health and Safety consulting services.",
      "With offices in Mississauga and Ottawa, Ontario, ECOH specializes in Hazardous Materials, Occupational Health & Safety, Environmental Consulting and Construction Management.",
      "Committed to providing clients with superior client service, and with a proven ability to respond quickly and efficiently 24/7 to client needs, ECOH offers unique solutions to environmental challenges. Mitigating environmental risk for commercial, industrial, institutional, and government clients across Canada and overseas.",
    ],
  },
  {
    id: 9,
    contacts: [
      {
        company: "EHS Partnerships Ltd.",
        name: "Kevin Schmidt",
        phone: "613-828-8989",
        fax: "613-828-9404",
        email: "kschmidt@ehsp.ca",
        address: "406-2 Gurdwara Road",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2E 1A2",
        website: "www.ehsp.com",
        latitude: 45.335376594907046,
        longitude: -75.70186836361006,
      },
      {
        company: "EHS Partnerships Ltd.",
        name: "Glyn Jones",
        phone: "403-243-0700",
        fax: "",
        email: "info@ehsp.ca",
        address: "4303 – 11 Street SE",
        city: "Calgary",
        province: "Alberta",
        postal: "T2G 4X1",
        website: "www.ehsp.com",
        latitude: 51.01482486959547,
        longitude: -114.03778432587664,
      },
      {
        company: "EHS Partnerships Ltd.",
        name: "Robbie Carrozza",
        phone: "780-395-0700",
        fax: "",
        email: "info@ehsp.ca",
        address: "4940 – 87 Street NW",
        city: "Edmonton",
        province: "Alberta",
        postal: "T6E 5W3",
        website: "www.ehsp.com",
        latitude: 51.103416367679884,
        longitude: -114.21661282582662,
      },
      {
        company: "EHS Partnerships Ltd.",
        name: "Mike Gordon",
        phone: "204-805-0960",
        fax: "",
        email: "info@ehsp.ca",
        address: "963 Henderson Hwy",
        city: "Winnipeg",
        province: "Manitoba",
        postal: "R2K 2M0",
        website: "www.ehsp.com",
        latitude: 49.93368041174354,
        longitude: -97.09589832881181,
      },
    ],
    logo: "/images/logos/consultants/ehs.png",
    profile: [
      "EHS Partnerships Ltd. is a full-service firm built around professionals and leaders in the field of environmental and occupational health and safety. With offices in Calgary, Edmonton, Winnipeg, Ottawa, and San Jose, EHSP provides occupational hygiene, hazardous materials, and safety and risk management consulting services to all North America. We serve all sectors including government, education, healthcare, energy, retail, manufacturing, residential, realty, construction, and engineering.",
    ],
  },

  {
    id: 10,
    contacts: [
      {
        company: "Englobe Corp.",
        name: "Steve March",
        phone: "1-877-300-4800",
        fax: "",
        email: "Steve.March@englobecorp.com",
        address: "3397 American Drive, Units 14 & 15",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4V 1T8",
        website: "www.englobecorp.com",
        latitude: 43.69878469803575,
        longitude: -79.61413034429934,
      },
    ],
    logo: "/images/logos/consultants/englobe.png",
    profile: [
      "Englobe Corp. is a national leader in environmental, geotechnical, pavement and materials engineering and building science services.  Our environmental group includes experts in asbestos, hazardous materials, mould, industrial & occupational hygiene, indoor air quality, demolition and blast consulting, flood response and waste audits.  The experience gained over the past 58 years by our professionals has allowed us to develop significant expertise, and to master the issues and challenges inherent in any type of project.  Our experience combined with local resources across Canada places us in a position to ensure all project objectives are met to the complete satisfaction of our clients.",
    ],
  },
  {
    id: 11,
    contacts: [
      {
        company: "Enviro Management",
        name: "Horace Faulknor",
        phone: "416-479-0446",
        fax: "416-479-0642",
        email: "hfaulknor@enviromgmtinc.com",
        address: "3 – 1750 The Queensway, Suite 1248",
        city: "Toronto",
        province: "Ontario",
        postal: "M9C 5H5",
        website: "www.enviromgmtinc.com",
        latitude: 43.62039003628568,
        longitude: -79.52938972981924,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 12,
    contacts: [
      {
        company: "Environmental Services Group Inc.",
        name: "Tara Valley",
        phone: "416-575-6111",
        fax: "905-264-0204",
        email: "tara@esgteam.ca",
        address: "12-350 Woodbridge Avenue",
        city: "Woodbridge",
        province: "Ontario",
        postal: "L4L 3K8",
        website: "www.environmentalservicesgroup.ca",
        latitude: 43.78674047337659,
        longitude: -79.59040654508091,
      },
    ],
    logo: "/images/logos/consultants/esg.png",
    services: "Asbestos, Indoor Air Quality, Lead, Mould, Vermiculite",
    profile: [
      "Environmental Services Group (ESG) is a Toronto-based, cutting edge professional Environmental Consulting Firm that provides professional environmental assessments on all property types including residential, commercial and industrial.",
      "ESG specializes in assessments regarding indoor air quality, designated substances (asbestos, lead), mold and more.",
    ],
  },
  {
    id: 13,
    contacts: [
      {
        company: "exp Services Inc.",
        name: "Manny Athwal",
        phone: "905-793-9800",
        fax: "",
        email: "manjinder.athwal@exp.com",
        address: "1595 Clark Boulevard",
        city: "Brampton",
        province: "Ontario",
        postal: "L6T 4V1",
        website: "www.exp.com",
        latitude: 43.73287375653789,
        longitude: -79.68907268372755,
      },
    ],
    logo: "",
    profile: [],
  },

  {
    id: "Fisher",
    contacts: [
      {
        company: "Fisher Engineering Limited",
        name: "David Fisher",
        phone: "905-475-7755",
        fax: "905-475-7718",
        email: "fisher@fishereng.com",
        address: "400 Esna Park Drive, Unit 15",
        city: "Markham",
        province: "Ontario",
        postal: "L3R 3K2",
        website: "www.fishereng.com",
        latitude: 43.821949931123,
        longitude: -79.33460834429535,
      },
    ],
    logo: "",
    profile: [],
    services: "",
  },
  {
    id: 14,
    contacts: [
      {
        company: "GHD Limited",
        name: "Fred Taylor",
        phone: "519-884-0510",
        fax: "519-884-0525",
        email: "red.taylor@ghd.com",
        address: "651 Colby Drive",
        city: "Waterloo",
        province: "Ontario",
        postal: "N2V 1C2",
        website: "www.ghd.com",
        latitude: 43.503921376827286,
        longitude: -80.53687811453243,
      },
    ],
    logo: "/images/logos/consultants/ghd.png",
    profile: [
      "GHD provides practical, innovative, and effective services in such areas as environmental health and safety, environmental site assessment and remediation, data management, regulatory compliance and permitting, solid and hazardous waste management, air quality management, property and buildings, energy and resources, transportation, and water and wastewater. GHD employs 8,500 staff in over 200 offices, including 14 Ontario locations.",
    ],
  },
  {
    id: 17,
    contacts: [
      {
        company: "Maple Environmental Inc.",
        name: "Kyle Prosser",
        phone: "905-257-4408",
        fax: "905-257-8865",
        email: "kprosser@mapleenv.com",
        address: "482 South Service Rd. East, Ste. 116",
        city: "Oakville",
        province: "Ontario",
        postal: "L6J 2X6",
        website: "www.mapleenvironmental.com",
        latitude: 43.46541400867142,
        longitude: -79.67849922989673,
      },
    ],
    logo: "/images/logos/consultants/maple.png",
    profile: [
      "Maple Environmental Inc. is an established health and safety consulting company, serving an array of clients from industrial facilities to educational and healthcare facilities. We provide services in the areas of Hazardous Building Materials, Indoor Environments, Environmental Site Assessments and Occupational and Environmental Health.",
    ],
  },
  {
    id: 18,
    contacts: [
      {
        company: "Martech Group Inc.",
        name: "Marwan Essa",
        phone: "1-855-291-4663",
        fax: "1-888-284-8253",
        email: "info@martechgroup.ca",
        address: "6635 Kitimat Road, Unit 40",
        city: "Mississauga",
        province: "Ontario",
        postal: "L5N 6J2",
        website: "www.martechgroup.ca",
        latitude: 43.63691884304369,
        longitude: -79.6444191605011,
      },
    ],
    logo: "/images/logos/consultants/martech.png",
    profile: [],
    custom: (
      <>
        <p className="members-component__text">
          Martech Group is a leading multidisciplinary engineering firm renowned
          for delivering innovative, high-quality solutions across diverse
          industries. With decades of experience and a solid commitment to
          excellence, we have built a reputation for providing comprehensive
          engineering and consulting services tailored to our client&#39;s
          unique needs. Our expertise spans various engineering disciplines,
          including Environmental, Building Science, Civil, Forensics, HAZMAT,
          Water Resources, and Hydrogeology.
        </p>
        <br />

        <p className="members-component__text">
          Our team of highly skilled professionals is dedicated to addressing
          the complex challenges faced by today’s environment, ensuring every
          project is executed with precision and integrity. From Regulatory
          Compliance to Full Suit Project Design, you can count on the team at
          Martech to get it done.
        </p>
      </>
    ),
  },
  {
    id: 19,
    contacts: [
      {
        company: "McIntosh Perry",
        name: "Mario Morana",
        phone: "905-856-5200",
        fax: "905-856-1455",
        email: "M.Morana@mcintoshperry.com",
        address: "6240 Highway 7, Suite 200",
        city: "Woodbridge",
        province: "Ontario",
        postal: "L4H 4G3",
        website: "www.mcintoshperry.com",
        latitude: 43.776198052807544,
        longitude: -79.62304272974104,
      },
    ],
    logo: "/images/logos/consultants/mcintosh-perry.png",
    profile: [
      "McIntosh Perry is a leading provider of consulting and engineering services in Canada. With more than 600 engineers, project managers and technical staff, our capabilities span the entire project life cycle. Our engineering services include the public infrastructure, private developers, buildings and oil & gas market sectors. Our history stretches back more than 50 years. As we’ve grown to become a national, multi-disciplinary engineering firm, we remain focused on one goal – helping you with your project. Our full range of consulting engineering services and technical solutions are designed to help us find an innovative solution to any challenge, big or small. We offer the highest level of client service to a wide range of clients in the public and private sectors: developers; REITs; building portfolio owners; property managers; government agencies; condominium corporations; architects, and contractors. Our expertise, gained through decades of experience, means you can rely on us to deliver high-quality and successful projects.",
    ],
  },
  {
    id: 20,
    contacts: [
      {
        company: "MTE Consultants Inc.",
        name: "Gavin Oakes",
        phone: "519-743-6500",
        fax: "519-743-6513",
        email: "goakes@mte85.com",
        address: "520 Bingemans Centre Dr",
        city: "Kitchener",
        province: "Ontario",
        postal: "N2B 3X9",
        website: "www.mte85.com",
        latitude: 43.47218241008197,
        longitude: -80.44613228756585,
      },
    ],
    logo: "/images/logos/consultants/mte.png",
    profile: [
      "MTE is an employee-owned company providing multidisciplinary services in the fields of engineering, toxicology, land surveying and environmental science (including assessments of mould, marijuana grow-operations, asbestos, designated substances, infection control, indoor air quality, building science, etc.) for industrial, commercial, institutional and residential clients . Since 1985, MTE has offices in Kitchener, Burlington, Guelph and Stratford.",
    ],
  },
  {
    id: 21,
    contacts: [
      {
        company: "OHE Consultants",
        name: "Fred Atrash",
        phone: "905-890-9000",
        fax: "905-890-9005",
        email: "info@oheconsultants.com",
        address: "311 Matheson Blvd East",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4Z 1X8",
        website: "www.oheconsultants.com",
        latitude: 43.622871532231564,
        longitude: -79.65998002981794,
      },
    ],
    logo: "/images/logos/consultants/ohe.png",
    profile: [
      "Services offered by OHE Consultants: environmental and occupational health and safety, hazardous materials, designated substances, asbestos, microbiological contamination (mould), indoor air quality, Phase 1 and 2 ESAs, site remediation, training programs, infection control, water quality testing, noise assessments, site decommissioning, training programs, Certificates of Approval (C of As).",
    ],
  },

  {
    id: 23,
    contacts: [
      {
        company: "Pinchin Ltd.",
        name: "Sean Douglas",
        phone: "905-363-0678",
        fax: "905-363-0681",
        email: "sdouglas@pinchin.com",
        address: "2360 Meadowpine Blvd., Unit 2",
        city: "Mississauga",
        province: "Ontario",
        postal: "L5N 6S2",
        website: "www.pinchin.com",
        latitude: 43.604918241517574,
        longitude: -79.77177271448187,
      },
      {
        company: "Pinchin Ltd.",
        name: "Kenton Hogarth",
        phone: "780-508-7000",
        fax: "",
        email: "khogarth@pinchin.com",
        address: "Suite 200, 9707 – 110 Street",
        city: "Edmonton",
        province: "Alberta",
        postal: "T5K 2L9",
        website: "www.pinchin.com",
        latitude: 53.53423898671184,
        longitude: -113.50998792443086,
      },
    ],
    logo: "/images/logos/consultants/pinchin.png",
    profile: [
      "Pinchin Environmental, part of the Pinchin Group of Companies, is one of Canada’s largest and most respected environmental, health and safety consulting firms. We offer our clients cost-effective, sustainable solutions in areas such as Hazardous Materials, Indoor Air Quality and Microbial Contamination, and Occupational Health and Safety Services and Training.",
    ],
  },
  {
    id: 24,
    contacts: [
      {
        company: "Premier Environmental Services",
        name: "Troy Jones",
        phone: "519-653-7140",
        fax: "519-653-8907",
        email: "tjones@premiercorp.ca",
        address: "1-244 Montrose St. N.",
        city: "Cambridge",
        province: "Ontario",
        postal: "N3H 2H7",
        website: "www.premiercorp.ca",
        latitude: 43.394864301600386,
        longitude: -80.34550871458687,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 25,
    contacts: [
      {
        company: "Reveal Environmental Inc.",
        name: "Jeff Lainsbury",
        phone: "416-300-6327",
        fax: "",
        email: "jlainsbury@revealenv.com",
        address: "218 Indian Grove",
        city: "Toronto",
        province: "Ontario",
        postal: "M6P 2H2",
        website: "www.revealenv.com",
        latitude: 43.54873579359607,
        longitude: -79.61192119916495,
      },
    ],
    logo: "",
    services: "Asbestos, Indoor Air Quality, Lead, Vermiculite",
    profile: [],
  },
  {
    id: 26,
    contacts: [
      {
        company: "RiskCheck Inc.",
        name: "Catherine Mills",
        phone: "416-640-2444",
        fax: "416-640-2445",
        email: "cmills@riskcheckinc.com",
        address: "4211 Yonge Street, Suite 605",
        city: "Toronto",
        province: "Ontario",
        postal: "M2P 2A9",
        website: "www.riskcheckinc.com",
        latitude: 43.748177265888714,
        longitude: -79.40715121441,
      },
    ],
    logo: "/images/logos/consultants/riskcheck.png",
    profile: [
      "As a Leader in Environmental Health & Safety Risk Management, we’ve been helping businesses like yours navigate the complexities of EHS compliance since 1999, so you can create a safer, more sustainable world. Whether you’re a growing start up, stable small business, or large corporation, we have the expertise to manage all your EHS, Hazmat, and Sustainability needs.",
    ],
  },
  {
    id: 27,
    contacts: [
      {
        company: "S2S Environmental Inc.",
        name: "Kevin Moore",
        phone: "416-410-4333",
        fax: "416-410-4088",
        email: "kmoore@s2se.com",
        address: "1099 Kingston Road, Suite 260",
        city: "Pickering",
        province: "Ontario",
        postal: "L1V 1B5",
        website: "www.s2se.com",
        latitude: 43.82908714443476,
        longitude: -79.0977516143694,
      },
    ],
    logo: "/images/logos/consultants/s2e-logo.png",
    profile: [
      "S2S Environmental Inc. is a leading professional environmental consulting firm established in 2003.  We specialize in all aspects of environmental due diligence offering our clients cost-effective and practical solutions.  Our services extend from hazardous material assessments and abatement programs for substances such as asbestos, lead, silica, and mould to Phase I and II Environmental Site Assessments, site remediation, indoor air quality, risk assessments and training programs.",
    ],
  },
  {
    id: 28,
    contacts: [
      {
        company: "Safetech Environmental Ltd.",
        name: "Glenn Smith",
        phone: "905-624-2722",
        fax: "905-624-4306",
        email: "gsmith@safetechenv.com",
        address: "3045 Southcreek Road, Unit #14",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4X 2X7",
        website: "www.safetechenv.com",
        latitude: 43.62601689527079,
        longitude: -79.56859722981639,
      },
    ],
    logo: "/images/logos/consultants/safetech.png",
    profile: [
      "Safetech Environmental Limited (SEL) has been formed a leader in the provision of consulting services where occupational health and safety concerns are raised. SELs goal is to provide efficient and cost effective solutions to our clients groups. This is achieved through years of experience and technical expertise. SELs range of services includes Hazardous Building Materials, Mould Contaminated Building Materials, Occupational Hygiene, IAQ, Infection Control During Construction and OH&S Training.",
    ],
  },
  {
    id: 29,
    contacts: [
      {
        company: "Stantec Consulting Ltd.",
        name: "Martin Ling",
        phone: "905-415-6386",
        fax: "905-474-9889",
        email: "martin.ling@stantec.com",
        address: "300W-675 Cochrane Drive",
        city: "Markham",
        province: "Ontario",
        postal: "L3R 0B8",
        website: "www.stantec.com",
        latitude: 43.846522403213456,
        longitude: -79.36607336746107,
      },
      {
        company: "Stantec Consulting Ltd.",
        name: "Sean Brigden",
        phone: "250-655-6062",
        fax: "",
        email: "sean.brigden@stantec.com",
        address: "11 - 2042 Mills Rd.",
        city: "Sidney",
        province: "British Columbia",
        postal: "V8L 5X4",
        website: "www.stantec.com",
        latitude: 48.655846541735976,
        longitude: -123.41639654232814,
      },
      {
        company: "Stantec Consulting Ltd.",
        name: "Patrick Turner",
        phone: "902-468-0435",
        fax: "",
        email: "patrick.turner@stantec.com",
        address: "102 – 40 Highfield Park Drive",
        city: "Dartmouth",
        province: "Nova Scotia",
        postal: "B3A 0A3",
        website: "www.stantec.com",
        latitude: 44.68998090794146,
        longitude: -63.588461274607674,
      },
      {
        company: "Stantec Consulting Ltd.",
        name: "Jacquie Elvish",
        phone: "807-285-9005",
        fax: "",
        email: "jacquie.elvish@stantec.com",
        address: "1263 Innovation Drive",
        city: "Thunder Bay",
        province: "Ontario",
        postal: "P7B 0A2",
        website: "www.stantec.com",
        latitude: 48.407576841414034,
        longitude: -89.28062582089595,
      },
    ],
    logo: "/images/logos/consultants/Stantec.jfif",
    profile: [
      "As a team of environmental professionals specialized in over 20 disciplines, we know what to look for. We know what’s required, and we excel at finding ways to match those requirements with project goals. You could say we know a little something about the environment.",

      "Our passion for it drives our work. When we’re gathering data to permit a new facility or collaborating on a plan to remediate an old one, we’re helping find the balance between progress and protection. Out in the field, we take time to understand the site. When we know the conditions, we can make recommendations that minimize or eliminate changes for you. Safety is also key: the ongoing commitment we’ve made to safety procedures extends from personal protective equipment to the Green Hand program for training our new field staff. Grounded by safety, quality, and ethics, we come up with solutions that perfectly fit the site.",

      " Whether it’s preparing a complex permit application, monitoring a site during construction, or designing a decommissioning plan, our team’s got the environment down to a science.",
    ],
  },
  {
    id: 30,
    contacts: [
      {
        company: "T. Harris Environmental",
        name: "Raj Singh",
        phone: "416-802-6086",
        fax: "866-750-1045",
        email: "rsingh@tharris.ca",
        address: "93 Skyway Avenue",
        city: "Toronto",
        province: "Ontario",
        postal: "M9W 6N6",
        website: "www.tharris.ca",
        latitude: 43.685607467484374,
        longitude: -79.58282196047669,
      },
      {
        company: "T. Harris Environmental",
        name: "Greg Balsden",
        phone: "519-685-9048",
        fax: "866-750-1045",
        email: "gbalsden@tharris.ca",
        address: "931 Commissioners Road East, Suite 100",
        city: "London",
        province: "Ontario",
        postal: "N5Z 3H9",
        website: "www.tharris.ca",
        latitude: 42.95909038804489,
        longitude: -81.21358386083857,
      },
      {
        company: "T. Harris Environmental",
        name: "Raj Singh",
        phone: "613-725-1554",
        fax: "866-750-1045",
        email: "rsingh@tharris.ca",
        address: "150 Isabella Street, Suite 206",
        city: "Ottawa",
        province: "Ontario",
        postal: "K1S 1V7",
        website: "www.tharris.ca",
        latitude: 45.40947456404505,
        longitude: -75.68880834232746,
      },

      {
        company: "T. Harris Environmental",
        name: "Christian Beaudin",
        phone: "450-465-9990",
        fax: "866-750-1045",
        email: "cbeaudin@tharris.ca",
        address: "4, Place du Commerce, Bureau 101",
        city: "Brossard",
        province: "Quebec",
        postal: "J4W 3B3",
        website: "www.tharris.ca",
        latitude: 45.46906176190809,
        longitude: -73.54226862887786,
      },
    ],
    logo: "/images/logos/consultants/t-harris.png",
    profile: [
      "Since 1979, delivering Assessments & Reassessments of Asbestos, Lead, Mould, Designated Substances Surveys, Air Quality Monitoring & Sampling, Noise Monitoring & Mapping, Emergency Response to Floods & Fire, Audits (Compliance, EHS, Gap Analysis, Waste, Environmental), Inspections, Occupational Health & Safety, Abatement Oversight Management & Monitoring, Employee EH&S Training, Occupational Hygiene Services, Environmental & Hygiene Consulting",
    ],
  },
  {
    id: 31,
    contacts: [
      {
        company: "Terrapex Environmental Ltd.",
        name: "Trevor Diseko",
        phone: "416-245-0011",
        fax: "416-245-0012",
        email: "T.Diseko@terrapex.com",
        address: "90 Scarsdale Road",
        city: "Toronto",
        province: "Ontario",
        postal: "M3B 2R7",
        website: "www.terrapex.com",
        latitude: 43.75107966576886,
        longitude: -79.35636704509878,
      },
      {
        company: "Terrapex Environmental Ltd.",
        name: "Rod Rose",
        phone: "613-745-6471",
        fax: "613-745-0796",
        email: "r.rose@terrapex.com",
        address: "20 Gurdwara Road, Unit 1",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2E 8E3",
        website: "www.terrapex.com",
        latitude: 45.33780209821068,
        longitude: -75.70486994429079,
      },
      {
        company: "Terrapex Environmental Ltd.",
        name: "Jeff Stevenson",
        phone: "905-632-5939",
        fax: "905-632-6793",
        email: "j.stevenson@terrapex.com",
        address: "65 Nebo Road",
        city: "Hamilton",
        province: "Ontario",
        postal: "L8W 2C9",
        website: "www.terrapex.com",
        latitude: 43.19578153846831,
        longitude: -79.83518603003101,
      },
    ],
    logo: "/images/logos/consultants/terrapex.png",
    profile: [
      "Established in 1995, Terrapex is a dynamic, 100% Canadian, employee-owned engineering and geosciences firm, with offices in Toronto, Burlington and Ottawa. In addition to environmental and geotechnical engineering, we provide related services such as Asbestos and Designated Substances Surveys (DSS), Hydrogeological Studies, and Building Condition Assessments. Our business is built upon our core values of integrity, technical excellence, personal service, and practical approach. We pride ourselves on our reputation for quality work, which is reflected by the number of repeat clients and referrals we earn. Terrapex’s professional staff have the technical excellence necessary to provide a practical approach to investigate, ascertain and mitigate environmental issues both on time and on budget.",
    ],
  },
  {
    id: 32,
    contacts: [
      {
        company: "TS Environmental Services",
        name: "Tony Samson",
        phone: "905-238-0362",
        fax: "905-238-0361",
        email: "staff@tsenvironmental.com",
        address: "2601 Matheson Blvd. Unit 35",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4W 5A8",
        website: "www.tsenvironmental.com",
        latitude: 43.659768044008835,
        longitude: -79.6002871144545,
      },
    ],
    logo: "",
    profile: [],
  },

  {
    id: 34,
    contacts: [
      {
        company: "WSP Golder",
        name: "Erin Kennealy",
        phone: "416-798-0065",
        fax: "",
        email: "Erin.Kennealy@wsp.com",
        address: "2 International Blvd",
        city: "Toronto",
        province: "Ontario",
        postal: "M9W 1A2",
        website: "www.wsp.com",
        latitude: 43.67794281723486,
        longitude: -79.59082152979039,
      },
      {
        company: "WSP Golder",
        name: "Stephen Heikkila",
        phone: "613-829-2800",
        fax: "",
        email: "Stephen.Heikkila@wsp.com",
        address: "2611 Queensview Dr #300",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2B 8K2",
        website: "www.wsp.com",
        latitude: 45.355215765595275,
        longitude: -75.78371529824655,
      },
      {
        company: "WSP Golder",
        name: "Martin Daigle",
        phone: "514-340-0046",
        fax: "",
        email: "martin.daigle@wsp.com",
        address: "1600 Boulevard René-Lévesque O 11 étage",
        city: "Montréal",
        province: "Ontario",
        postal: "H3H 1P9",
        website: "www.wsp.com",
        latitude: 45.49361131521544,
        longitude: -73.57505472886518,
      },
      {
        company: "WSP Golder",
        name: "Katelyn Zinz",
        phone: "250-980-5500",
        fax: "",
        email: "Katelyn.Zinz@wsp.com ",
        address: "Landmark 6, 700-1631 Dickson Ave",
        city: "Kelowna",
        province: "British Columbia",
        postal: "V1Y 0B5",
        website: "www.wsp.com",
        latitude: 49.880300482696974,
        longitude: -119.46139354185642,
      },
    ],
    logo: "/images/logos/consultants/wsp.png",
    profile: [
      " WSP is a globally recognized professional services firm. Our talented people are well positioned to deliver successful and sustainable projects, wherever our clients need us.",
      " Throughout 2019-2021, we welcomed experts from 14 different acquisitions – including the transformational acquisition of Golder, a global consulting firm with over 60 years of experience in providing earth sciences and environmental consulting services.",
    ],
  },
  {
    id: 35,
    contacts: [
      {
        company: "XCG Consultants Ltd.",
        name: "Dale White",
        phone: "613-542-5888",
        fax: "613-542-0844",
        email: "dale.white@xcg.com",
        address: "4 Cataraqui St. Suite 100",
        city: "Kingston ",
        province: "Ontario",
        postal: "K7K 1Z7",
        website: "www.xcg.com",
        latitude: 44.24220241103423,
        longitude: -76.48077471854936,
      },
    ],
    logo: "/images/logos/consultants/xcg.png",
    profile: [],
  },
  {
    id: 36,
    contacts: [
      {
        company: "T. Smith Engineering Inc.",
        name: "James Smith",
        phone: "416-798-8770",
        fax: "",
        email: "james@tsmithengineering.com",
        address: "707 Kipling Avenue",
        city: "Etobicoke",
        province: "Ontario",
        postal: "M8Z 5G4",
        website: "www.tsmithengineering.com",
        latitude: 43.624918966302936,
        longitude: -79.5278310288353,
      },
      {
        company: "T. Smith Engineering Inc.",
        name: "Rob Thomson",
        phone: "416-798-8770",
        fax: "",
        email: " rob@tsmithengineering.com",
        address: "707 Kipling Avenue",
        city: "Etobicoke",
        province: "Ontario",
        postal: "M8Z 5G4",
        website: "www.tsmithengineering.com",
        latitude: 43.624918966302936,
        longitude: -79.5278310288353,
      },
    ],
    logo: "/images/logos/consultants/tsmith.png",
    profile: [
      "Founded in 2008, T. Smith Engineering Inc. has grown into a bustling, fast-paced work environment. Every step of our process places an emphasis on client needs and efficient outcomes. Our services encompass structural, environmental, mechanical, and technological support engineering. Our dynamic staff environment is ever-growing and adapting to new projects and atmospheres.",
    ],
  },
];
